:root {
  /* Enhanced color palette */
  --primary: #38b6ff;
  --primary-light: #6ad3ff;
  --primary-dark: #0056b3; /* New professional deep blue color */
  --secondary: #00bfa6;
  --secondary-light: rgba(166, 255, 223, 0.8);
  --accent: #ff6b6b;
  --text: #333333;
  --text-light: #666666;
  --background: #ffffff;
  --background-alt: #f8fbff;
  --background-gradient: linear-gradient(135deg, #f8fbff 0%, #e0f4ff 100%);
  --radius-sm: 10px;
  --radius-md: 16px;
  --radius-lg: 28px;
  --shadow: 0 10px 30px rgba(56, 182, 255, 0.1);
  --shadow-lg: 0 20px 40px rgba(56, 182, 255, 0.15);
  --transition: all 0.3s ease;
}

/* Dark Mode Variables */
.dark-mode {
  --text: #f0f0f0;
  --text-light: #b0b0b0;
  --background: #121212;
  --background-alt: #1a1a1a;
  --background-gradient: linear-gradient(135deg, #1a1a1a 0%, #222222 100%);
  --shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  --shadow-lg: 0 20px 40px rgba(0, 0, 0, 0.6);
  --primary-dark: #0072e5; /* Slightly brighter in dark mode */
}

.dark-mode header {
  background-color: rgba(18, 18, 18, 0.95);
}

.dark-mode .logo {
  filter: brightness(0) invert(1);
}

.dark-mode .feature-card {
  background-color: #1e1e1e;
}

.dark-mode .nav-link {
  color: var(--text);
}

.dark-mode footer {
  background: linear-gradient(135deg, #1a1a1a 0%, #222222 100%);
}

.dark-mode .social-link {
  background-color: rgba(255, 255, 255, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  color: var(--text);
  background-color: var(--background);
  line-height: 1.6;
  overflow-x: hidden;
  transition: var(--transition);
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1.2;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 28px;
  font-weight: 600;
  border-radius: var(--radius-md);
  transition: var(--transition);
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(56, 182, 255, 0.2);
}

/* Updated button styles with the new professional color */
.btn-primary {
  background: linear-gradient(120deg, var(--primary) 0%, var(--primary-dark) 100%);
  color: white;
  border: none;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(56, 182, 255, 0.3);
}

.btn-secondary {
  background-color: var(--secondary);
  color: white;
}

.btn-secondary:hover {
  background-color: #00a895;
  transform: translateY(-3px);
}

.btn-outline {
  border: 2px solid var(--primary);
  color: var(--primary);
  background: transparent;
  box-shadow: none;
}

.btn-outline:hover {
  background-color: var(--primary);
  color: white;
  box-shadow: 0 5px 15px rgba(56, 182, 255, 0.2);
}

/* Header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  transition: var(--transition);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Modified Header Logo Size - Making it larger */
.logo {
  height: 60px; /* Increased from 50px to 60px */
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-link {
  color: var(--text);
  text-decoration: none;
  font-weight: 600;
  transition: var(--transition);
  position: relative;
}

.nav-link:not(.btn)::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--primary);
  transition: var(--transition);
}

.nav-link:not(.btn):hover::after {
  width: 100%;
}

.nav-link:hover {
  color: var(--primary);
}

.nav-link.active {
  color: var(--primary);
  font-weight: 700;
}

/* Dark Mode Toggle Button */
.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: var(--text);
  padding: 5px;
  margin-left: 15px;
  transition: var(--transition);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

/* Desktop-only dark mode toggle */
.dark-mode-toggle.desktop-only {
  display: flex; /* Show by default on desktop */
}

.dark-mode-toggle:hover {
  background-color: rgba(56, 182, 255, 0.1);
}

.dark-mode .dark-mode-toggle:hover {
  background-color: rgba(56, 182, 255, 0.2);
}

/* Hero Section */
.hero {
  padding: 160px 0 100px;
  background: var(--background-gradient);
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(106, 211, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
  top: -200px;
  right: -200px;
  z-index: 0;
}

.hero::after {
  content: '';
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(56, 182, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
  bottom: -100px;
  left: -100px;
  z-index: 0;
}

.hero-container {
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  z-index: 1;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

/* Enhanced hero title gradient for more professional look */
.hero-title {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 20px;
  background: linear-gradient(120deg, var(--primary) 0%, var(--primary-dark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 22px;
  color: var(--text-light);
  margin-bottom: 30px;
}

.store-badges {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.store-badge {
  height: 48px;
  transition: var(--transition);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.store-badge:hover {
  transform: translateY(-5px);
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.15));
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 20px 40px rgba(56, 182, 255, 0.2));
  animation: float 6s ease-in-out infinite;
  position: relative;
  z-index: 2;
  border-radius: 28px;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Features Section */
.features {
  padding: 30px 0;
  background-color: var(--background);
  position: relative;
}

.features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(106, 211, 255, 0.05) 0%, transparent 20%),
    radial-gradient(circle at 90% 80%, rgba(56, 182, 255, 0.05) 0%, transparent 20%);
  z-index: 0;
}

.section-title {
  text-align: center;
  vertical-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

/* Enhanced section title color */
.section-title h2 {
  font-size: 38px;
  color: var(--primary-dark);
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.section-title h2::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary-light) 100%);
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.section-title p {
  font-size: 18px;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  position: relative;
  z-index: 1;
}

.feature-card {
  background-color: var(--background);
  border-radius: var(--radius-lg);
  padding: 50px 30px;
  box-shadow: var(--shadow);
  transition: var(--transition);
  height: 100%;
  position: relative;
  overflow: hidden;
}

/* Enhanced feature card top border */
.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary-dark) 100%);
  transition: var(--transition);
  opacity: 0.8;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 28px;
  filter: drop-shadow(0 5px 10px rgba(56, 182, 255, 0.2));
  transition: var(--transition);
}

.feature-card:hover .feature-icon {
  transform: scale(1.1);
}

.feature-title {
  font-size: 22px;
  margin-bottom: 16px;
  color: var(--primary);
}

.feature-description {
  color: var(--text-light);
  font-size: 16px;
}

/* App Showcase Sections */
.app-showcase {
  padding: 100px 0;
  position: relative;
}

.app-showcase:nth-child(odd) {
  background-color: var(--background-alt);
}

.showcase-container {
  display: flex;
  align-items: center;
  gap: 80px;
}

.showcase-content {
  flex: 1;
}

.showcase-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 24px;
  color: var(--primary);
  position: relative;
}

.showcase-title::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary-light) 100%);
  bottom: -10px;
  left: 0;
  border-radius: 2px;
}

.showcase-description {
  font-size: 18px;
  color: var(--text-light);
  margin-bottom: 30px;
  line-height: 1.7;
}

.showcase-image {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.showcase-image::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 80%;
  background: radial-gradient(circle, rgba(56, 182, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  z-index: 0;
}

.showcase-image img {
  max-width: 100%;
  height: auto;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.showcase-image:hover img {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(56, 182, 255, 0.2);
}

.reverse {
  flex-direction: row-reverse;
}

/* Call to Action */
.cta {
  padding: 120px 0;
  background: linear-gradient(135deg, rgba(106, 211, 255, 0.15) 0%, rgba(56, 182, 255, 0.2) 100%);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta::before {
  content: '';
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(56, 182, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
  top: -200px;
  right: 10%;
  z-index: 0;
}

.cta::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(106, 211, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
  bottom: -100px;
  left: 5%;
  z-index: 0;
}

/* Enhanced CTA title gradient */
.cta-title {
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 25px;
  background: linear-gradient(120deg, var(--primary) 0%, var(--primary-dark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.cta-description {
  font-size: 20px;
  color: var(--text);
  margin-bottom: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

/* Newsletter */
.newsletter {
  padding: 100px 0;
  background-color: var(--background-alt);
  position: relative;
}

.newsletter::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 5% 20%, rgba(56, 182, 255, 0.05) 0%, transparent 15%),
    radial-gradient(circle at 95% 80%, rgba(106, 211, 255, 0.05) 0%, transparent 15%);
}

.newsletter-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.newsletter-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--primary);
}

.newsletter-form {
  display: flex;
  gap: 15px;
  margin-top: 40px;
}

.newsletter-input {
  flex: 1;
  padding: 16px 22px;
  border: 1px solid #e0e0e0;
  border-radius: var(--radius-md);
  font-size: 16px;
  outline: none;
  transition: var(--transition);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
}

.newsletter-input:focus {
  border-color: var(--primary);
  box-shadow: 0 5px 20px rgba(56, 182, 255, 0.1);
}

/* Footer */
footer {
  padding: 40px 0 20px;
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  color: white;
  position: relative;
  overflow: hidden;
}

footer::before {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
  top: 10%;
  right: 5%;
}

footer::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 70%);
  bottom: -150px;
  left: 10%;
}

/* Modified Footer Styles */
.simplified-footer {
  padding: 1.5rem 0; /* Reduced padding */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 0; /* Removed margin-bottom to make footer more compact */
}

.enlarged-logo {
  height: 55px; /* Increased from 45px to 55px */
  width: auto;
}

.footer-contact {
  margin: 0 1rem;
}

.footer-email {
  color: white; /* Changed from var(--primary) to white for better visibility */
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: var(--transition);
  padding: 8px 16px;
  border-radius: var(--radius-sm);
  background-color: rgba(255, 255, 255, 0.15); /* Changed background color */
}

.footer-email:hover {
  background-color: rgba(255, 255, 255, 0.25);
  text-decoration: underline;
}

.social-links {
  display: flex;
  gap: 15px;
}

.social-link {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.social-link:hover {
  background-color: rgba(255, 255, 255, 0.25);
  transform: translateY(-5px);
}

.social-icon {
  width: 20px;
  height: 20px;
  fill: white;
}

.copyright {
  margin-top: 30px; /* Reduced from 50px */
  padding-top: 20px; /* Reduced from 30px */
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center; /* Ensure text is centered */
  width: 100%; /* Make sure it spans the full width */
}

/* Also add this to be extra safe */
.copyright p {
  text-align: center;
}

/* Media Queries */
@media (max-width: 992px) {
  .hero-title {
    font-size: 40px;
  }
  
  .showcase-container {
    flex-direction: column;
    gap: 60px;
  }
  
  .showcase-container.reverse {
    flex-direction: column;
  }
  
  .showcase-image::before {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .hero-container,
  .section-title,
  .feature-card,
  .showcase-content,
  .newsletter-container,
  .cta,
  .footer-container {
    text-align: center;
  }
  
  .feature-icon {
    margin-left: auto;
    margin-right: auto;
  }
  
  .showcase-title::before {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .header-container {
    padding: 0 15px;
  }
  
  .nav-links {
    display: none; /* Hide regular nav links on mobile */
  }
  
  .mobile-menu-btn {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    z-index: 1001;
  }
  
  .mobile-menu-btn span {
    display: block;
    width: 100%;
    height: 2px;
    background: var(--text);
    position: absolute;
    left: 0;
    transition: var(--transition);
  }
  
  .mobile-menu-btn span:nth-child(1) {
    top: 8px;
  }
  
  .mobile-menu-btn span:nth-child(2) {
    top: 16px;
  }
  
  .mobile-menu-btn span:nth-child(3) {
    top: 24px;
  }
  
  .mobile-menu-open .mobile-menu-btn span:nth-child(1) {
    transform: rotate(45deg);
    top: 14px;
  }
  
  .mobile-menu-open .mobile-menu-btn span:nth-child(2) {
    opacity: 0;
  }
  
  .mobile-menu-open .mobile-menu-btn span:nth-child(3) {
    transform: rotate(-45deg);
    top: 14px;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    right: -280px; /* Start off-screen */
    width: 280px;
    height: 100vh;
    background: var(--background);
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: var(--transition);
    padding: 80px 30px 30px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  .mobile-menu-open .mobile-menu {
    right: 0;
  }
  
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
  }
  
  .mobile-menu-open .menu-overlay {
    opacity: 1;
    visibility: visible;
  }
  
  .mobile-nav-link {
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--text);
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: var(--transition);
    display: block;
    text-align: left;
  }
  
  .mobile-nav-link:hover {
    color: var(--primary);
  }
  
  .mobile-nav-link.btn {
    margin-top: 20px;
    text-align: center;
    border-bottom: none;
  }
  
  .dark-mode .mobile-menu {
    background: var(--background-alt);
  }
  
  .dark-mode .mobile-nav-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .dark-mode .mobile-menu-btn span {
    background: var(--text);
  }

  /* Hide the regular dark mode toggle on mobile */
  .dark-mode-toggle {
    display: none;
  }
  
  /* Even larger logo on mobile for better visibility */
  .logo {
    height: 50px; /* Keep mobile logo size reasonable */
  }

  /* Hide desktop toggle on mobile */
  .dark-mode-toggle.desktop-only {
    display: none;
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 32px;
  }
  
  .feature-title {
    font-size: 20px;
  }
  
  .showcase-title {
    font-size: 28px;
  }
  
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .cta-title {
    font-size: 30px;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 1rem;
  }
  
  .social-links {
    margin-top: 1rem;
  }
}

/* Mobile Menu Styles */
.mobile-menu-btn {
  display: none; /* Hidden by default, will be shown on mobile */
  width: 30px;
  height: 24px;
  position: relative;
  cursor: pointer;
  z-index: 1010;
}

.mobile-menu-btn span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--text);
  position: absolute;
  left: 0;
  transition: var(--transition);
}

.mobile-menu-btn span:nth-child(1) {
  top: 0;
}

.mobile-menu-btn span:nth-child(2) {
  top: 10px;
}

.mobile-menu-btn span:nth-child(3) {
  top: 20px;
}

/* Active state of hamburger menu */
.mobile-menu-btn.active span:nth-child(1) {
  top: 10px;
  transform: rotate(45deg);
}

.mobile-menu-btn.active span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-btn.active span:nth-child(3) {
  top: 10px;
  transform: rotate(-45deg);
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  right: -280px; /* Off-screen by default */
  width: 280px;
  height: 100vh;
  background-color: var(--background);
  z-index: 1000;
  padding: 80px 30px 30px;
  transition: right 0.3s ease;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.mobile-menu.open {
  right: 0; /* Show menu when open class is added */
}

.mobile-nav-link {
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--text);
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: var(--transition);
  display: block;
}

.mobile-nav-link:hover {
  color: var(--primary);
}

.mobile-nav-link.btn {
  margin-top: 20px;
  text-align: center;
  border-bottom: none;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 990;
  display: none;
}

.menu-overlay.visible {
  display: block;
}

.mobile-social {
  margin-top: 30px;
  justify-content: center;
}

/* Dark mode adjustments for mobile menu */
.dark-mode .mobile-menu {
  background-color: var(--background-alt);
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
}

.dark-mode .mobile-nav-link {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

/* Mobile Menu Styles - Adding theme toggle to mobile menu */
.mobile-theme-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: var(--text);
  font-weight: 600;
  font-size: 18px;
}

.dark-mode .mobile-theme-toggle {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.mobile-theme-toggle .label {
  margin-right: auto;
}

.mobile-theme-toggle .toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  transition: var(--transition);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--text);
}

/* Social links in mobile menu */
.mobile-social {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Show mobile menu button only on mobile devices */
@media (max-width: 768px) {
  .mobile-menu-btn {
    display: block;
  }
  
  .nav-links {
    display: none;
  }
}

/* Add this to your Desktop.css */

/* Fix inconsistency with dark mode toggle */
.site-wrapper {
  min-height: 100vh;
  transition: var(--transition);
}

.site-wrapper.dark-mode {
  --text: #f0f0f0;
  --text-light: #b0b0b0;
  --background: #121212;
  --background-alt: #1a1a1a;
  --background-gradient: linear-gradient(135deg, #1a1a1a 0%, #222222 100%);
  --shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  --shadow-lg: 0 20px 40px rgba(0, 0, 0, 0.6);
  --primary-dark: #0072e5; /* Slightly brighter in dark mode */
}

.site-wrapper.dark-mode header {
  background-color: rgba(18, 18, 18, 0.95);
}

.site-wrapper.dark-mode .logo {
  filter: brightness(0) invert(1);
}

/* Additional fixed selector for header dark mode */
header.dark-mode {
  background-color: rgba(18, 18, 18, 0.95) !important;
}

header.dark-mode .logo {
  filter: brightness(0) invert(1);
}

header.dark-mode .nav-link {
  color: var(--text);
}

/* Fix the Dark Mode Toggle button appearance */
.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  margin-left: 15px;
  transition: var(--transition);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--text); /* This should change with dark mode */
}