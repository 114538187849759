@import url("https://fonts.googleapis.com/css2?family=Mukta:ital,wght@0,500;0,600;0,700;0,800;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-sf-pro: "SF Pro";
  --font-mukta: Mukta;
  --h3: Montserrat;

  /* font sizes */
  --font-size-lgi: 19px;
  --font-size-mini: 15px;
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --h3-size: 24px;
  --font-size-18xl: 37px;
  --font-size-11xl: 30px;
  --font-size-3xl: 22px;
  --font-size-7xl: 26px;
  --font-size-2xl: 21px;
  --font-size-mid: 17px;
  --font-size-lg: 18px;
  --font-size-42xl: 61px;
  --font-size-30xl: 49px;
  --font-size-6xl: 25px;
  --font-size-3xs: 10px;
  --font-size-4xl: 23px;
  --font-size-17xl: 36px;
  --font-size-8xl: 27px;
  --font-size-21xl: 40px;
  --font-size-10xl: 29px;
  --font-size-31xl: 50px;

  /* Colors */
  --light-background-color: #fff;
  --color-darkslategray-100: #2e2d2d;
  --text-color: #252b42;
  --color-whitesmoke: #f6f6f6;
  --color-whitesmoke-100: #f5f5f5;
  --color-lightcyan: #e1fdff;
  --color-gray: rgba(0, 0, 0, 0.2);
  --color-black: #000;
  --color-seagreen: #4f9773;
  --color-dodgerblue: #0e9aff;
  --color-mediumslateblue: #4563ff;
  --second-text-color: #737373;
  --danger-color: #e74040;
  --color-teal: #006369;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-8xl: 27px;
  --gap-4xs: 9px;
  --gap-3xs: 10px;
  --gap-9xl-6: 28.6px;
  --gap-35xl: 54px;
  --gap-3xl-5: 22.5px;
  --gap-lg: 18px;
  --gap-26xl-1: 45.1px;
  --gap-4xl: 23px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-21xl: 40px;
  --padding-9xs-5: 3.5px;
  --padding-26xl: 45px;
  --padding-xl: 20px;
  --padding-smi: 13px;
  --padding-24xl: 43px;
  --padding-3xl: 22px;
  --padding-30xl-5: 49.5px;
  --padding-10xl-5: 29.5px;
  --padding-119xl-4: 138.4px;
  --padding-16xl: 35px;
  --padding-30xl: 49px;
  --padding-13xl: 32px;
  --padding-66xl: 85px;
  --padding-36xl: 55px;
  --padding-3xs: 10px;
  --padding-7xl: 26px;
  --padding-8xs: 5px;
  --padding-6xl: 25px;
  --padding-4xs: 9px;
  --padding-10xl: 29px;
  --padding-116xl: 135px;
  --padding-48xl: 67px;
  --padding-base: 16px;
  --padding-5xl: 24px;
  --padding-28xl: 47px;
  --padding-9xl: 28px;
  --padding-12xs-4: 0.4px;
  --padding-127xl-6: 146.6px;
  --padding-76xl: 95px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-11xl: 30px;
  --br-31xl: 50px;

  /* Effects */
  --accentued-drop-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
}
